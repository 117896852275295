import React from 'react';
import {motion} from 'framer-motion';
import {graphql} from 'gatsby';
import {pageVariants, pageTransition} from 'components/Transitions';

import Layout from 'components/Layout';
import {PageHeader} from 'components/Headings';

function Privacy({data}) {
  const {
    craftPrivacyPolicyPrivacyPolicyEntry: {heading, body, seomatic},
  } = data;

  return (
    <Layout seomatic={seomatic}>
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}>
        <div className="article">
          <div className="bg-black text-white bg-gradient-to-r from-black to-dark max-h-screen relative">
            <PageHeader heading={heading} />
          </div>
          <div className="bg-white">
            <div className="max-w-screen-xl m-auto w-full pt-16 pb-12 px-6 xl:px-0 flex">
              <div
                className="flex-initial lg:mr-12 pr-6 md:pr-12 article-body font-display"
                dangerouslySetInnerHTML={{__html: body}}
              />
            </div>
          </div>
        </div>
      </motion.div>
    </Layout>
  );
}

export const query = graphql`
  query PrivacyPolicyQuery {
    craftPrivacyPolicyPrivacyPolicyEntry {
      seomatic {
        ...SeomaticFields
      }
      heading
      body
    }
  }
`;

export default Privacy;
